<template>
    <div class="d-flex flex-column fill-height">
        <v-col>
            <v-row no-gutters class="align-center">
                <v-col class="col-9-5 d-flex flex-column justify-space-between">
                    <div class="pb-5">{{ $t('message.date') + ": " + formatDate(Wpr__wprdate) }}</div>
                    <div class="pt-0">{{ $t('message.location') + ": " + (Wpr__office_id != null ? offices.find(office => office.Office.id == Wpr__office_id).Office.title : '') }}</div>
                </v-col>
                <v-col class="col-2-5 text-right">
                    <img
                        :src="appLogo"
                        class="mb-3 mr-3"
                        width="120px"
                    />
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-4">
                <v-col cols="12" class="text-uppercase pa-0 font-weight-bold document-caption font-lg">{{ $t('message.warehousePurchaseRequest') + " " + Wpr__title }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-3">
                <v-col cols="12" class="font-weight-bold">{{ $t('message.poDetails') }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.supplier') }}:</v-col>
                <v-col cols="6">{{ Supplier__title }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.agreedPaymentTerms') }}:</v-col>
                <v-col cols="6">{{ Paymentstatement__title }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-3">
                <v-col cols="12" class="font-weight-bold">{{ $t('message.specifications') }}</v-col>
            </v-row>
            <v-row no-gutters v-for="(item, index) in WprItems" v-bind:key="index">
                <v-col cols="12">
                    <v-row no-gutters :class="index > 0 ? 'pt-3' : ''">
                        <v-col class="col-0-75">{{ "#" + (index + 1).toString()  }}</v-col>
                        <v-col class="col-5-25">{{ $t('message.certification') }}:</v-col>
                        <v-col cols="6">{{ getCertification(item.Wpritem.certification_id) }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="col-0-75"></v-col>
                        <v-col class="col-5-25">{{ $t('message.species') }}:</v-col>
                        <v-col cols="6">{{ item.Species.title }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="col-0-75"></v-col>
                        <v-col class="col-5-25">{{ $t('message.description') }}:</v-col>
                        <v-col cols="6">{{ item.Wpritem.description ? item.Wpritem.description : item.Spec.title }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="col-0-75"></v-col>
                        <v-col class="col-5-25">{{ $t('message.grade') }}:</v-col>
                        <v-col cols="6">{{ item.Grade.title }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="col-0-75"></v-col>
                        <v-col class="col-5-25">{{ $t('message.additionalDescription') }}:</v-col>
                        <v-col cols="6">{{ item.Wpritem.add_desc }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="col-0-75"></v-col>
                        <v-col class="col-5-25">{{ $t('message.size') }}:</v-col>
                        <v-col cols="6">{{ item.Size.title }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="col-0-75"></v-col>
                        <v-col class="col-5-25">{{ $t('message.volume') }}:</v-col>
                        <v-col cols="6">{{ formatThisNumber(item.Wpritem.volume,uofmPrecision(item.Measurement.title)) }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="col-0-75"></v-col>
                        <v-col class="col-5-25">{{ $t('message.measurement') }}:</v-col>
                        <v-col cols="6">{{ item.Measurement.title }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="col-0-75"></v-col>
                        <v-col class="col-5-25">{{ $t('message.unitPrice') }}:</v-col>
                        <v-col cols="6">{{ formatThisNumber(item.Wpritem.unitprice,currencyFormatPrecision) }}</v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row no-gutters class="pt-3">
                <v-col cols="12" class="font-weight-bold">{{ $t('message.otherDetails') }}</v-col>
                <v-col cols="6">{{ $t('message.totalValue') + getIncoTerm(Wpr__incoterm_id) }}:</v-col>
                <v-col cols="6">{{ Wpr__cfrvalue != null ? formatThisNumber(Wpr__cfrvalue,currencyFormatPrecision) + " " + currencies.find(c => c.Currency.id == Wpr__currency_id).Currency.code : '' }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.containerCount') }}:</v-col>
                <v-col cols="6" v-if="Wpr__containersize_id != null">
                    <span v-if=" [1,2,3,5].includes(Wpr__containersize_id) ">{{ Wpr__containercount }}</span>
                    <span v-if=" [1,2,3,5].includes(Wpr__containersize_id) "> X </span>
                    <span>{{ containerSizes.find(containerSize => containerSize.Containersize.id == Wpr__containersize_id).Containersize.title }}</span>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.suggestedETA') }}:</v-col>
                <v-col cols="6">{{ Wpr__etadate != null ? formatDate(Wpr__etadate) : '' }}</v-col>
            </v-row>

            <v-row no-gutters class="pt-3">
                <v-col cols="12" class="font-weight-bold">{{ $t('message.currentStockDetails') }}</v-col>
                <v-col cols="6">{{ $t('message.currentStockInSameItems') }}:</v-col>
                <v-col cols="6">{{ Wpr__current_stock != null ? Wpr__current_stock : '' }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.currentStockPoNumber') }}:</v-col>
                <v-col cols="6">{{ Wpr__ponumber != null ? Wpr__ponumber : '' }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.etaCurrentStockInSameItems') }}:</v-col>
                <v-col cols="6">{{ Wpr__eta_current_stock != null ? formatDate(Wpr__eta_current_stock) : '' }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.expectedTimeOfSalesOfCurrentStock') }}:</v-col>
                <v-col cols="6">{{ Wpr__expected_salesdate_current != null ? formatDate(Wpr__expected_salesdate_current) : '' }}</v-col>
            </v-row>

            <v-row no-gutters class="pt-3">
                <v-col cols="12" class="font-weight-bold">{{ $t('message.intransitStockDetails') }}</v-col>
                <v-col cols="6">{{ $t('message.intransitStockInSameItems') }}:</v-col>
                <v-col cols="6">{{ Wpr__transit_stock }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.intransitStockPoNumber') }}:</v-col>
                <v-col cols="6">{{ Wpr__transit_ponumber }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.etaIntransitStockInSameItems') }}:</v-col>
                <v-col cols="6">{{ Wpr__transit_eta_stock != null ? formatDate(Wpr__transit_eta_stock) : '' }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.expectedTimeOfSalesOfInTransitStock') }}:</v-col>
                <v-col cols="6">{{ Wpr__transit_expected_salesdate != null ? formatDate(Wpr__transit_expected_salesdate) : '' }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-3">
                <v-col cols="12" class="font-weight-bold">{{ $t('message.newPODetails') }}</v-col>
                <v-col cols="6">{{ $t('message.etdOfNewPo') }}:</v-col>
                <v-col cols="6">{{ Wpr__etddate != null ? formatDate(Wpr__etddate) : ''}}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.etaOfNewPo') }}:</v-col>
                <v-col cols="6">{{ Wpr__etadate != null ? formatDate(Wpr__etadate) : ''}}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.expectedTimeOfSalesOfNewPO') }}:</v-col>
                <v-col cols="6">{{ Wpr__expected_salesdate_newpo != null ? formatDate(Wpr__expected_salesdate_newpo) : '' }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.explanationForPurchase') }}:</v-col>
                <v-col cols="6">{{ Wpr__explanation }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-3">
                <v-col cols="12" class="font-weight-bold">{{ $t('message.status') }}</v-col>
                <v-col cols="6">{{ $t('message.status') }}:</v-col>
                <v-col cols="6">{{ Wpr__status_id != null ? wprStatuses.find(s => s.Wprstatus.id == Wpr__status_id).Wprstatus.title : '' }}</v-col>
            </v-row>
        </v-col>
        <h1 id="loaded" v-if="loaded" style="margin-bottom: 0px !important;"></h1>
    </div>
</template>

<script>
import {formatDate, log, numberFormat} from "../../../helpers/helpers";
    import {api} from "../../../api";
    import AppConfig from "Constants/AppConfig";
    import {
        // certifications,
        // certificationsNew,
        containerSizes,
        // currencies,
        incoterms,
        offices,
        wprStatuses,
        uofm
    } from "../../../store/modules/appic/constants"

    export default {
        name: "PrintWpr",
        props: ['wprId'],
        data() {
            return {
                appLogo: AppConfig.appSessionLogo,
                loaded: false,
                // certifications: certifications,
                // certificationsNew: certificationsNew,
                certificationsNew: [],
                containerSizes: containerSizes,
                // currencies: currencies,
                currencies: [],
                incoterms: incoterms,
                offices: offices,
                uofmQty: uofm.qty,
                wprStatuses: wprStatuses,
                Paymentstatement__title: null,
                Supplier__title: null,
                Wpr__cfrvalue: null,
                Wpr__containercount: 1,
                Wpr__containersize_id: 1,
                Wpr__currency_id: 2,
                Wpr__current_stock: null,
                Wpr__eta_current_stock: null,
                Wpr__etadate: null,
                Wpr__etddate: null,
                Wpr__expected_salesdate_current: null,
                Wpr__expected_salesdate_newpo: null,
                Wpr__explanation: null,
                Wpr__incoterm_id: 2,
                Wpr__office_id: 1,
                Wpr__paymentstatement_id: null,
                Wpr__ponumber: null,
                Wpr__status_id: null,
                Wpr__title: null,
                Wpr__transit_eta_stock: null,
                Wpr__transit_expected_salesdate: null,
                Wpr__transit_ponumber: null,
                Wpr__transit_stock: null,
                Wpr__wprdate: new Date().toISOString().substr(0,10),
                WprItems: []
            }
        },
        computed: {
            currencyFormatPrecision () {
                return this.currencies.find((currency) => currency.Currency.id === this.Wpr__currency_id)?.Currency?.formatPrecision
            },
        },
        methods: {
            formatDate,
            formatThisNumber(value,format){
                return numberFormat(value,format)
            },
            getCertification(certificationId) {
                let certificationObj = this.certificationsNew.find(c => c.Certification.id == certificationId)
                if(certificationObj){
                    return certificationObj.Certification.title
                }
                return ''
            },
            getCertifications() {
                return new Promise((resolve, reject) => {
                    api
                        .get("/print/certifications",{
                        })
                        .then(response => {
                            resolve(response.data.data)
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
            },
            getCurrencies() {
                return new Promise((resolve, reject) => {
                    log('get currencies')
                    api
                        .get("/print/currencies",{
                        })
                        .then(response => {
                            resolve(response.data.data)
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
            },
            getIncoTerm(incoTermId) {
                let incoTermObj = this.incoterms.find(i => i.Incoterm.id == incoTermId)
                if(incoTermObj){
                    return ' ' + incoTermObj.Incoterm.title
                }
                return ''
            },
            loadWprById (wprId) {
                let self = this
                return new Promise((resolve, reject) => {
                    api
                        .get("/print/wprs/" + wprId,{
                        })
                        .then(response => {
                            for(let key in response.data.data[0]){
                                self[key] = response.data.data[0][key]
                            }
                            resolve(wprId)
                        })
                        .catch(error => {
                            reject(error);
                        })
                        .finally(()=>{
                            resolve(wprId)
                        })
                })
            },
            loadItemsByWpr (wprId) {
                let self = this
                return new Promise((resolve, reject) => {
                    api
                        .get("/print/wprs/" + wprId + '/items',{
                        })
                        .then(response => {
                            self.WprItems = response.data.data
                            resolve(wprId)
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
            },
            uofmPrecision (uofm) {
                return this.uofmQty.find((u) => u.Measurement.title == uofm.trim())?.Measurement?.formatPrecision
            },
        },
        created () {
            this.getCertifications()
                .then((certifications) => {
                    this.certificationsNew = certifications
                    this.getCurrencies()
                        .then((currencies) => {
                            this.currencies = currencies
                        })
                        .catch()
                })
                .catch()
            if(this.$route.matched.some(({name}) => name === 'print_wpr')){
                this.loadWprById(this.wprId)
                    .then(()=>{
                        this.loadItemsByWpr(this.wprId)
                            .then(() => {
                                this.loaded = true
                            })
                    })
            }
        }
    }
</script>

<style>
    @font-face {
        font-family: 'AppicArial';
        src: url('/static/fonts/Arial.woff2') format('woff2'),
        url('/static/fonts/Arial.woff2') format('woff'),
        url('/static/fonts/Arial.woff2') format('truetype');
    }
    .col-0-75 {
        flex: 0 0 6.24999999%;
        max-width: 6.24999999%;
    },
    .col-5-25 {
        flex: 0 0 43.749999999%;
        max-width: 43.749999999%;
    },
    .col-2-5 {
        flex: 0 0 20.83333333%;
        max-width: 20.83333333%;
    }
    .col-9-5 {
        flex: 0 0 79.16666667%;
        max-width: 79.16666667%;
    }
    .v-application{
        font-family: 'AppicArial' !important;
        line-height:15px !important;
        font-size:9pt !important;
        padding:2px !important;
    }
    body {
        width: 18.5cm !important;
        margin: 0 !important;
    }
</style>